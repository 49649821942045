import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;

Vue.use(VueCookies);
Vue.$cookies.config('60d');

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
